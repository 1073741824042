Vue.component('homeItemSection', {
    props:['section'],
    data:function (){
        return {
            items:[]
        };
    },
    watch: {
        '$route': 'forceUpdate',
        '$store.state.itemTimeFlag':'forceUpdate',
        '$store.state.itemUpdate': 'forceUpdate'
    },
    methods:{
        forceUpdate:function () {
            this.items = [];
            let itemsSelected = [];
            for(let item of this.$store.getters.getHomeItems) {
                let store_item = this.$store.getters.getItemByCode(item.fields.ItemCode);
                switch (this.section.type) {
                    case 'itemCard':
                        if (store_item) {
                            if (item.fields.PageSection == this.section.sectionValue){
                                itemsSelected.push(store_item);
                            }
                        }
                        break;
                    case 'itemList':
                        if (store_item) {
                            if (item.fields.PageSection == this.section.sectionValue){
                                itemsSelected.push(store_item);
                            }
                        }
                        break;
                    case 'slider':
                        if (item.fields.PageSection == this.section.sectionValue)
                            itemsSelected.push(item);
                        break;
                }
            }
            this.items = itemsSelected;
        },
        getItem(itemCode){
            return this.$store.getters.getItemByCode(itemCode);
        }
    },
    mounted:function (){
        $('.slider').slider();
        this.forceUpdate();
    },
    template:`<div class="home-produktliste" v-show="items.length>0">
                  <template v-if="section.type=='itemList'" >
                      <h2>{{section.title}} ({{items.length}})</h2>
                      <ul class="row produktliste">
                          <template v-for="item of items">
                              <li class="col l3 m6 s12" v-if="item">
                                    <itemViewModuleComponent :item="item" :showCrossOutPrice="true"></itemViewModuleComponent>
                              </li>
                          </template>
                      </ul>
                  </template>    
                  <template v-if="section.type=='slider'">
                     <sliderSectionComponent :items="items" :config="section"/>
                  </template>
                  <template v-if="section.type=='itemCard'">
                     <homeItemCardComponent :items="items" :section="section"/>
                  </template>
              </div>`
});
